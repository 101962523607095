import React from "react";
// import { withRouter } from "react-router-dom";
// Common components
import PageContainer from "../components/PageContainer";
import SEO from "../components/seo";
import BenefitSection from "../components/Sections/BenefitSection";
import ClientsSections from "../components/Sections/ClientsSection";
import ComplianceSection from "../components/Sections/ComplianceSection";
import ContactSection from "../components/Sections/ContactSection";
import DisinfectionSlider from "../components/DisinfectionSlider";
import FaqSection from "../components/Sections/FaqSection";
import FeaturedBySection from "../components/Sections/FeaturedBySection";
import IntroSection from "../components/Sections/IntroSection";
import PriceSection from "../components/Sections/PriceSection";
import ProcessSection from "../components/Sections/ProcessSection";
import SeoTextSection from "../components/Sections/SeoTextSection";
import ThankYouModal from "../components/ThankYouModal";
import WhySection from "../components/Sections/WhySection";
import { Commercial as WebTable } from "../components/WebTable";
import { Commercial as MobileTable } from "../components/MobileTable";
import DisinfectionGallerySection from "../components/Sections/DisinfectionGallerySection";
import CallBackForm from "../components/CallBackForm";
import GetQuoteModal from "../components/GetQuoteModal";
// Constants
import { Commercial as CommercialFaq } from "../constants/FaqDetails";
import { Commercial as CommercialBenefit } from "../constants/BenefitDetails";
import WhyDetails from "../constants/WhyDetails";
import ProcessDetails from "../constants/ProcessDetails/Commercial";
import { useTranslation } from "gatsby-plugin-react-i18next";
// Custom components
// import CallBackForm from "./../components/CallBackForm";

const Commercial = () => {
  const { t } = useTranslation();
  const sgFlag = require("../images/singapore.svg");
  const bgVideo = require("../assets/videos/intro_bg2.mp4");
  const CommercialCallbackForm = () => (
    <CallBackForm leads="commercial-disinfection" />
  );

  return (
    <PageContainer>
      <SEO title={t("COMMERCIAL.seoTitleCommercial")} />
      <div>
        {/* <!-- Compliance banner --> */}
        <ComplianceSection
          image={sgFlag}
          imageAlt="SG Flag"
          title={t("COMMERCIAL.complianceBannerTitleCommercial")}
        />
        {/* <!-- Intro --> */}

        <IntroSection
          video={bgVideo}
          topSubtitle={t("COMMERCIAL.introTopSubtitleCommercial")}
          title={t("COMMERCIAL.introTitleCommercial")}
          body={t("COMMERCIAL.introContentCommercial")}
          bodyTagline={t("COMMERCIAL.introTaglingCommercial")}
          buttonText={t("COMMERCIAL.introCTAButtonCommercial")}
          form={CommercialCallbackForm}
        />
        {/* <!-- Clients we served --> */}
        <ClientsSections />
        {/* <!-- Featured by Companies --> */}
        <FeaturedBySection />
        {/* <!-- PRICES AND OPTIONS (TABLE) --> */}
        <PriceSection web={WebTable} mobile={MobileTable} contact />
        {/* <!-- WHY GET DISINFECTION (highlights) --> */}
        <WhySection data={WhyDetails} />
        <DisinfectionGallerySection commercial />
        {/* <!-- DISINFECTION PROCESS --> */}
        <ProcessSection advancedTreatment data={ProcessDetails} lang="en" />
        {/* <!-- WHO WOULD BENEFIT? --> */}
        <BenefitSection data={CommercialBenefit} />
        {/* <!-- FAQ --> */}
        <FaqSection spec data={CommercialFaq} />
        {/* <!-- CTA --> */}
        <ContactSection form={CommercialCallbackForm} />
        {/* <!-- SEO --> */}
        <SeoTextSection />
        <GetQuoteModal />
        <ThankYouModal />
      </div>
    </PageContainer>
  );
};

export default Commercial;
